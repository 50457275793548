'use client';

import { FC, useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { liveChatStore } from 'src/stores/liveChatStore';

export const Chat: FC = () => {
  const { visibility, setLiveChatVisibility } = liveChatStore(
    ({ visibility, setLiveChatVisibility }) => ({
      visibility,
      setLiveChatVisibility,
    }),
    shallow,
  );

  // Delay the livechat widget loading as much as possible
  useEffect(() => {
    document.addEventListener('readystatechange', stateChangeEvent);

    if (document.readyState === 'complete') {
      initChatWidget();
    }

    return () => {
      document.removeEventListener('readystatechange', stateChangeEvent);
    };
  }, []);

  useEffect(() => {
    window.LiveChatWidget?.call(getVisibility(visibility));
  }, [visibility]);

  useEffect(() => {
    const handleVisibilityChange = (data: { visibility: 'minimized' | 'maximized' | 'hidden' }) => {
      setLiveChatVisibility(data.visibility);
    };

    window.LiveChatWidget?.on('visibility_changed', handleVisibilityChange);

    return () => {
      window.LiveChatWidget?.off('visibility_changed', handleVisibilityChange);
    };
  }, [setLiveChatVisibility]);

  return null;
};

const initChatWidget = () => {
  window.setTimeout(() => {
    window.LiveChatWidget?.init();
  }, 5000);
};

const stateChangeEvent = (event: Event) => {
  if (event.target instanceof Document && event.target.readyState === 'complete') {
    initChatWidget();
  }
};

const getVisibility = (visibility?: string) => {
  switch (visibility) {
    case 'hidden':
      return 'hide';
    case 'minimized':
      return 'minimize';
    case 'maximized':
      return 'maximize';
    default:
      return 'hide';
  }
};
