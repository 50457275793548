import { carmaCsrBizClient } from 'src/services/instance/carmaBizClient';
import { ProductDetailsPayload } from 'src/types/ProductDetail.type';

export const getCarDetail = (sku: string) =>
  carmaCsrBizClient
    .get<ProductDetailsPayload>(`vehicles/${sku}`, {
      headers: { 'Content-Type': 'application/pdp+json' },
    })
    .json()
    .catch(() => null);
