import { produce } from 'immer';

import { storeFactory } from './storeFactory';

type Visibility = undefined | 'hidden' | 'minimized' | 'maximized';

interface LiveChatStoreStateData {
  visibility: Visibility;
}

interface LiveChatStoreStateFunctions {
  hideLiveChat: () => void;
  minimiseLiveChat: () => void;
  maximiseLiveChat: () => void;
  setLiveChatVisibility: (visibility: Visibility) => void;
}

const {
  store: liveChatStore,
  StoreProvider: LiveChatStoreProvider,
  useCreateStore: useCreateLiveChatStore,
} = storeFactory<LiveChatStoreStateData, LiveChatStoreStateFunctions>(
  () => ({
    visibility: undefined,
  }),
  (set) => ({
    setLiveChatVisibility: (visibility: Visibility) => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = visibility;
        }),
      );
    },
    hideLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'hidden';
        }),
      );
    },
    minimiseLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'minimized';
        }),
      );
    },
    maximiseLiveChat: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.visibility = 'maximized';
        }),
      );
    },
  }),
);

export { liveChatStore, LiveChatStoreProvider, useCreateLiveChatStore };
