import { useRouter } from 'next/navigation';
import { FC, useCallback, useEffect } from 'react';
import type { ToastContentProps } from 'react-toastify';
import useSWR, { Fetcher, useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { shallow } from 'zustand/shallow';

import { fetchVehicleFilters } from 'src/data/ProductApi/ProductApiListVehicles';
import { SAVED_SEARCH_DEFAULT_NAME, createSavedSearch, getSavedSearches } from 'src/data/SavedSearchApi/SavedSearchApi';
import { Button } from 'src/general/components/Button/Button';
import { ToasterService } from 'src/general/components/Toaster/ToasterService';
import { getOptimisticSavedSearches } from 'src/general/helpers/getOptimisticSavedSearches';
import { authStore } from 'src/stores/authStore';
import { savedSearchStore } from 'src/stores/savedSearchStore';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

const DEFAULT_FOCUS_THROTTLE = 60 * 1000;

export const SavedSearchManager: FC = () => {
  const router = useRouter();
  const { data: filterData } = useSWRImmutable(['/vehicle-filters', { includeMakesWithNoResult: true }], ([_, props]) =>
    fetchVehicleFilters(props),
  );
  const { mutate } = useSWRConfig();
  const { isUserLoggedIn, userAuthDataLoading } = authStore(
    (state) => ({
      isUserLoggedIn: state.isUserLoggedIn,
      userAuthDataLoading: state.userAuthDataLoading,
    }),
    shallow,
  );

  const {
    setSavedSearchesLoading,
    setSavedSearchesData,
    setFilterData,
    clearSavedSearchesData,
    setSavedSearchesInitialised,
  } = savedSearchStore(
    (state) => ({
      setSavedSearchesLoading: state.setSavedSearchesLoading,
      setSavedSearchesData: state.setSavedSearchesData,
      setFilterData: state.setFilterData,
      clearSavedSearchesData: state.clearSavedSearchesData,
      setSavedSearchesInitialised: state.setSavedSearchesInitialised,
    }),
    shallow,
  );

  const loadApiSavedSearchesData: Fetcher<Awaited<ReturnType<typeof getSavedSearches>>, {}> = async () => {
    setSavedSearchesLoading();
    const result = await getSavedSearches();

    return result;
  };

  const snackbarAction = useCallback(
    ({ closeToast }: Pick<ToastContentProps, 'closeToast'>) => (
      <Button href="/account/notifications" variant="tertiary" size="small" onClick={closeToast}>
        Manage
      </Button>
    ),
    [],
  );

  // Handle loading and refreshing of the saved search API
  const { data: savedSearchesData } = useSWR(
    isUserLoggedIn && !userAuthDataLoading ? '/me/saved-searches' : null,
    loadApiSavedSearchesData,
    {
      revalidateOnFocus: true,
      focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
      refreshInterval: 0,
    },
  );

  useEffect(() => {
    let mounted = true;
    if (isUserLoggedIn && !userAuthDataLoading) {
      const currentUrl = new URL(window.location.toString());
      const pendingSavedSearchRaw = currentUrl.searchParams.get('savedSearch');
      if (pendingSavedSearchRaw) {
        try {
          const pendingSavedSearch = JSON.parse(pendingSavedSearchRaw);
          const optimisticData = getOptimisticSavedSearches(
            savedSearchesData?.searches ?? [],
            null,
            true,
            pendingSavedSearch,
          );
          // Create the pending saved search
          mutate(
            '/me/saved-searches',
            () =>
              createSavedSearch({ filters: pendingSavedSearch, name: SAVED_SEARCH_DEFAULT_NAME, is_similar_to: null }),
            {
              optimisticData,
              rollbackOnError: true,
            },
          ).then(() => {
            pushToDataLayer({
              event: 'toggle_save_search-post_login',
              active: true,
            });
            if (mounted) {
              ToasterService.success({
                message: 'Your search alert is active',
                action: {
                  type: 'custom',
                  actionComponent: snackbarAction,
                },
              });

              currentUrl.searchParams.delete('savedSearch');
              router.replace(currentUrl.toString());
            }
          });
        } catch (error) {
          console.warn('Invalid pending saved search');
          console.error(error);
        }
      }
    } else {
      // clear the cart data
      clearSavedSearchesData();
      // Ensure the saved search is set as initialised
      setSavedSearchesInitialised(true);
    }
    return () => {
      mounted = false;
    };
  }, [isUserLoggedIn, userAuthDataLoading]);

  useEffect(() => {
    // Ensure we update the saved searches only when we have results
    if (typeof savedSearchesData !== 'undefined' && typeof savedSearchesData.searches !== 'undefined') {
      setSavedSearchesData(savedSearchesData.searches);
    }
  }, [savedSearchesData]);

  useEffect(() => {
    if (filterData) {
      setFilterData(filterData);
    }
  }, [filterData]);

  return null;
};
