'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';

import { Chat } from '../Chat/Chat';

const Recaptcha = dynamic(() => import('src/general/components/Recaptcha/Recaptcha').then((mod) => mod.Recaptcha), {
  ssr: false,
});

/**
 * Load third party libraries such as LiveChat or Recaptcha
 */
export const ThirdPartyLibraries: FC = () => (
  <>
    <Chat />
    <Recaptcha />
  </>
);
