import { FC, useEffect } from 'react';
import { COOKIE_BROWSER_ID, COOKIE_USER_GROUP, COOKIE_USER_ID } from 'src/constants';
import { getCookieExpiryTracking } from 'src/general/helpers/getCookieExpiry';
import { getUserGroup } from 'src/general/helpers/getUserGroup';
import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

/**
 * Component in charge of assigning and updating user cookies
 */
export const WebsiteCookies: FC = () => {
  useEffect(() => {
    handleTrackingCookies();
  }, []);

  return null;
};

/**
 * Handle tracking cookies used by the carma-data API
 * Cookies include: browser ID, User ID and User group
 */
const handleTrackingCookies = async () => {
  let browserId = '';
  const cookieBrowserId: string | undefined = cookies.get(COOKIE_BROWSER_ID) ?? undefined;
  const cookieUserId: string | undefined = cookies.get(COOKIE_USER_ID) ?? undefined;
  const cookieUserGroup: string | undefined = cookies.get(COOKIE_USER_GROUP) ?? undefined;
  const userGroup = await getUserGroup(cookieUserGroup, cookieUserId);

  // Generate or re-use the browser id
  if (cookieBrowserId) {
    browserId = cookieBrowserId;
  } else {
    browserId = crypto.randomUUID();
  }

  // Rolling expiry for the cookies
  const cookieOption: CookieSetOptions = {
    expires: getCookieExpiryTracking(),
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'lax',
  };
  cookies.set(COOKIE_BROWSER_ID, browserId, cookieOption);
  cookies.set(COOKIE_USER_GROUP, userGroup, cookieOption);
  if (cookieUserId) {
    cookies.set(COOKIE_USER_ID, cookieUserId, cookieOption);
  }
};
