'use client';

import { ComponentProps } from 'react';
import type { ToastContentProps as ToastProps } from 'react-toastify';

import { ToasterContent } from './ToasterContent';

type ToasterVariants = 'success' | 'info' | 'warning' | 'error';
type ToasterContentProps = Omit<ComponentProps<typeof ToasterContent>, 'variant' | 'closeToast' | 'toastId'>;

export const ToasterService = {
  /**
   * General method to display a toast with a custom variant
   */
  async show(variant: ToasterVariants, props: ToasterContentProps, toastProps: Partial<ToastProps['toastProps']> = {}) {
    const toast = (await import('react-toastify')).toast;
    toast[variant](
      ({ closeToast, toastProps: { toastId } }: any) => (
        <ToasterContent {...props} variant={variant} closeToast={closeToast} toastId={toastId} />
      ),
      toastProps,
    );
  },

  /**
   * Convenience methods for each variant
   */
  success(props: ToasterContentProps, toastProps?: Partial<ToastProps['toastProps']>) {
    this.show('success', props, toastProps);
  },

  info(props: ToasterContentProps, toastProps?: Partial<ToastProps['toastProps']>) {
    this.show('info', props, toastProps);
  },

  warning(props: ToasterContentProps, toastProps?: Partial<ToastProps['toastProps']>) {
    this.show('warning', props, toastProps);
  },

  error(props: ToasterContentProps, toastProps?: Partial<ToastProps['toastProps']>) {
    this.show('error', props, toastProps);
  },
};
